import type { extendTailwindMerge } from "tailwind-merge"
import { tailwindFontSizes } from "./tailwind-font-sizes"

export const tailwindMergeConfig: Parameters<typeof extendTailwindMerge>[0] = {
  override: {
    classGroups: { "font-size": [{ text: Object.keys(tailwindFontSizes) }] },
    theme: {
      colors: [
        "gray-100",
        "blue",
        "purple",
        "orange",
        "bg-primary",
        "bg-secondary",
        "transparent",
      ],
    },
  },
}
