import type { Config } from "tailwindcss"

export const tailwindFontSizes: NonNullable<
  NonNullable<Config["theme"]>["fontSize"]
> = {
  "marketing-large": [
    "3.5rem",
    {
      lineHeight: "120%",
      fontWeight: 700,
    },
  ],
  "marketing-medium": [
    "2rem",
    {
      lineHeight: "120%",
      fontWeight: 700,
    },
  ],
  h1: [
    "1.5rem",
    {
      lineHeight: "120%",
      fontWeight: 600,
    },
  ],
  h2: [
    "1.25rem",
    {
      lineHeight: "120%",
      fontWeight: 600,
    },
  ],
  h3: [
    "1rem",
    {
      lineHeight: "120%",
      fontWeight: 600,
    },
  ],
  big: [
    "1rem",
    {
      lineHeight: "120%",
      fontWeight: 500,
    },
  ],
  stronger: [
    "0.8125rem",
    {
      lineHeight: "150%",
      fontWeight: 600,
    },
  ],
  strong: [
    "0.8125rem",
    {
      lineHeight: "150%",
      fontWeight: 500,
    },
  ],
  base: [
    "0.8125rem",
    {
      lineHeight: "150%",
      fontWeight: 400,
    },
  ],
  "small-medium": [
    "0.75rem",
    {
      lineHeight: "150%",
      fontWeight: 500,
    },
  ],
  small: [
    "0.75rem",
    {
      lineHeight: "150%",
      fontWeight: 400,
    },
  ],

  label: [
    "0.6875rem",
    {
      fontWeight: 600,
      lineHeight: "120%",
    },
  ],
  "label-small": [
    "0.5625rem",
    {
      fontWeight: 600,
      lineHeight: "120%",
    },
  ],
}
